import React from 'react';
import { Link } from 'react-router-dom';
import libu from '../assets/projects/libu.png';
import groumit from '../assets/projects/groumit.png';

const Proyectos = () => {
  return (
    <div className="flex mx-auto w-11/12 lg:w-8/12 flex-col pb-16 md:pb-32">
      <div className="mb-8 md:mb-14">
        <p className="wow fadeInUp text-white uppercase font-inter font-black text-[32px] leading-10 md:text-[48px] md:leading-[45px]">
          algunos de nuestros <br />
          proyectos
        </p>
      </div>
      <div className="wow fadeInUp h-[80%] flex flex-col md:flex-row pb-5 md:pb-0 gap-20">
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] mb-3">
            libu
          </p>
          <div className="flex max-w-[350px] h-[150px] md:h-[350px] my-5">
            <img src={libu} className="md:m-auto"></img>
          </div>
          <p className="text-white font-sora text-[18px] hidden md:block mb-5">
            Libu es el primer proyecto que comenzamos en conjunto, en simples
            palabras es un ecosistema de herramientas de marketing Web3.
          </p>
          <Link
            className="font-sora text-white text-[16px] hover:text-[#51FDED] transition duration-150"
            to="/proyectos/libu"
          >
            ver más
          </Link>
        </div>
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] mb-3">
            groumit
          </p>
          <div className="flex max-w-[350px] h-[150px] md:h-[350px] my-5">
            <img src={groumit} className="md:m-auto"></img>
          </div>
          <p className="text-white font-sora text-[18px] hidden md:block mb-5">
            Groumit es una plataforma web3 construida como capa de utilidad
            sobre Lens Protocol.
          </p>
          <Link
            className="font-sora text-white text-[16px] hover:text-[#51FDED] transition duration-150"
            to="/proyectos/groumit"
          >
            ver más
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Proyectos;

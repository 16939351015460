import React from 'react';
import et from '../assets/tools/et.png';
import fg from '../assets/tools/fg.png';
import hh from '../assets/tools/hh.png';
import ipfs from '../assets/tools/ipfs.png';
import mt from '../assets/tools/mt.png';
import nt from '../assets/tools/nt.png';
import nx from '../assets/tools/nx.png';
import rb from '../assets/tools/rb.png';
import rt from '../assets/tools/rt.png';
import sl from '../assets/tools/sl.png';
import tw from '../assets/tools/tw.png';
import ul from '../assets/tools/ul.png';

const Herramientas = () => {
  return (
    <div className="flex mx-auto w-11/12 lg:w-8/12 flex-col pb-20 md:pb-32">
      <div className="mb-8 md:mb-14">
        <p className="wow fadeInUp text-white uppercase font-inter font-black text-[32px] leading-10 md:text-[48px] md:leading-[45px]">
          que herramientas <br />
          usamos
        </p>
      </div>
      <div className="wow fadeInUp grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-7">
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={et}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={fg}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={hh}></img>
        <img className="m-auto max-h-[44px] md:max-h-[84px]" src={ipfs}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={mt}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={nt}></img>

        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={nx}></img>
        <img className="m-auto max-h-[20px] md:max-h-[74px]" src={rb}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={rt}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={sl}></img>
        <img className="m-auto max-h-[34px] md:max-h-[51px]" src={tw}></img>
        <img className="m-auto max-h-[44px] md:max-h-[74px]" src={ul}></img>
      </div>
    </div>
  );
};

export default Herramientas;

import React from 'react';
import '../index.css';

const QuienesSomos = () => {
  return (
    <div className="flex mx-auto w-11/12 lg:w-8/12 flex-col">
      <div className="mb-8 md:mb-14">
        <p className="wow fadeInUp text-white uppercase font-inter font-black text-[32px] leading-10 md:text-[48px] md:leading-[45px]">
          Quienes Somos
        </p>
      </div>
      <div className="wow fadeInUp flex justify-between gap-7 w-[full] flex-col md:flex-row pb-5 lg:pb-0">
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <div className="flex md:flex-col w-full gap-7 lg:gap-0 h-fit">
            <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] md:mb-10">
              product
              <br />
              manager
            </p>
            <p className="text-white uppercase font-sora text-[24px] leading-[30px] mb-3">
              francisco
              <br />
              actis
            </p>
          </div>
          <div className="flex flex-row md:flex-col w-full mb-10 md:mb-0">
            <div className="fa bg-white flex w-[348px] md:w-full h-[100px] md:h-[350px] border-0 border-[#141D20] hover:border-2 hover:border-[#141D20] hover:shadow-lg shadow-[#51FDED] transition duration-150"></div>
            <div className="flex flex-col md:flex-row my-auto ml-5 md:ml-0 w-6 md:w-auto h-fit md:h-6 gap-7 md:mt-5">
              <a
                href="https://www.linkedin.com/in/franciscoactisdanna/"
                className="h-6 w-6"
              >
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn</title>
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a href="https://twitter.com/Franactis" className="h-6 w-6">
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <div className="flex md:flex-col w-full gap-7 lg:gap-0 h-fit">
            <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] md:mb-10">
              solidity
              <br />
              developer
            </p>
            <p className="text-white uppercase font-sora text-[24px] leading-[30px] mb-3">
              luca
              <br />
              auet
            </p>
          </div>
          <div className="flex flex-row md:flex-col w-full mb-10 md:mb-0">
            <div className="la bg-white flex w-[348px] md:w-full h-[100px] md:h-[350px] border-0 border-[#141D20] hover:border-2 hover:border-[#141D20] hover:shadow-lg shadow-[#51FDED] transition duration-150"></div>
            <div className="flex flex-col md:flex-row my-auto ml-5 md:ml-0 w-6 md:w-auto h-fit md:h-6 gap-7 md:mt-5">
              <a
                href="https://www.linkedin.com/in/lucaauet/"
                className="h-6 w-6"
              >
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn</title>
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a href="https://twitter.com/0xLucca" className="h-6 w-6">
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <div className="flex md:flex-col w-full gap-7 lg:gap-0 h-fit">
            <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] md:mb-10">
              frontend
              <br />
              developer
            </p>
            <p className="text-white uppercase font-sora text-[24px] leading-[30px] mb-3">
              gerardo
              <br />
              barboni
            </p>
          </div>
          <div className="flex flex-row md:flex-col w-full mb-10 md:mb-0">
            <div className="gb bg-white flex w-[348px] md:w-full h-[100px] md:h-[350px] border-0 border-[#141D20] hover:border-2 hover:border-[#141D20] hover:shadow-lg shadow-[#51FDED] transition duration-150"></div>
            <div className="flex flex-col md:flex-row my-auto ml-5 md:ml-0 w-6 md:w-auto h-fit md:h-6 gap-7 md:mt-5">
              <a
                href="https://www.linkedin.com/in/gerardobarboni/"
                className="h-6 w-6"
              >
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn</title>
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a href="https://twitter.com/gera_barboni" className="h-6 w-6">
                <svg
                  className="fill-white hover:fill-[#51FDED] transition duration-150"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomos;

import React from 'react';
import Points from '../components/Points';

const Hero = () => {
  return (
    <div className="h-screen flex mx-auto -mt-12">
      <div className="m-auto w-[80%] md:w-[70%] h-[80%] md:h-[70%] flex">
        <p
          data-wow-delay="2.5"
          className="z-50 m-auto wow fadeInUpBig uppercase font-inter font-black text-white text-center text-[36px] leading-[40px] md:text-[65px] md:leading-[60px] lg:text-[96px] lg:leading-[95px]"
        >
          <span className="text-[#1A2E35]">DE</span>construimos
          <br />
          ideas en <span className="text-[#51FDED]">web3</span>
        </p>
        <Points />
      </div>
    </div>
  );
};

export default Hero;

import React, { useEffect, useState } from 'react';
import { projects } from '../data/projects';
import { Link, useParams } from 'react-router-dom';
import libu from '../assets/projects/libu.png';
import groumit from '../assets/projects/groumit.png';
import WOW from 'wowjs';

const Project = () => {
  const { project } = useParams();
  const [projectToShow, setprojectToShow] = useState(null);
  const componentDidMount = () => {
    new WOW.WOW({
      live: false,
    }).init();
  };
  useEffect(() => {
    componentDidMount();
  }, []);
  useEffect(() => {
    if (project === 'libu') {
      setprojectToShow(projects[0]);
    }
    if (project === 'groumit') {
      setprojectToShow(projects[1]);
    }
    console.log(projects);
  }, []);
  useEffect(() => {
    console.log(projectToShow);
  }, [projectToShow]);

  return (
    <>
      <div className="min-h-screen bg-[#141D20] pb-[120px]">
        {projectToShow !== null && (
          <div className="flex mx-auto w-11/12 lg:w-8/12 flex-col pt-5">
            <Link
              to={'/'}
              data-wow-delay="2.5"
              className="wow fadeInUpBig text-white hover:text-[#51FDED] transition duration-150 uppercase font-sora font-black text-[16px] leading-10 md:text-[24px] md:leading-[45px] mb-3 lg:mb-5"
            >
              volver
            </Link>
            <p
              data-wow-delay="2.5"
              className="wow fadeInUpBig text-white uppercase font-inter font-black text-[36px] leading-10 md:text-[48px] md:leading-[45px] mb-3 lg:mb-5"
            >
              {projectToShow.name}
            </p>
            <div className="flex flex-col md:flex-row-reverse justify-between">
              <div
                data-wow-delay="3.5"
                className="wow fadeInUpBig max-h-[300px] max-w-[300px] flex my-8"
              >
                {project === 'libu' && <img src={libu}></img>}
                {project === 'groumit' && <img src={groumit}></img>}
              </div>

              <div data-wow-delay="2.5" className="w-8/12 wow fadeInUpBig">
                {projectToShow.p.map((p) => (
                  <>
                    <p className="text-white md:mt-11 font-sora text-[16px]">
                      {p}
                    </p>
                    <br />
                  </>
                ))}
                {projectToShow.li !== undefined &&
                  projectToShow.li.map((li) => (
                    <>
                      <p className="text-white md:mt-11 font-sora text-[16px]">
                        {li}
                      </p>
                      <br />
                    </>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-[#141D20] w-full flex ">
        <div className="mx-auto h-14 ">
          <p className="m-auto font-sora text-[#1A2E35]">
            desarrollado por Libu Factory
          </p>
        </div>
      </div>
    </>
  );
};

export default Project;

import React from 'react';
import Hero from '../slides/Hero';
import QueHacemos from '../slides/QueHacemos';
import Herramientas from '../slides/Herramientas';
import Proyectos from '../slides/Proyectos';
import QuienesSomos from '../slides/QuienesSomos';
import Gracias from '../slides/Gracias';

const Home = () => {
  return (
    <div className="">
      <Hero />
      <QueHacemos />
      <Herramientas />
      <Proyectos />
      <QuienesSomos />
      <Gracias />
    </div>
  );
};

export default Home;

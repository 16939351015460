import React from 'react';

const Gracias = () => {
  return (
    <div className="wow fadeInUpBig min-h-screen flex flex-col mx-auto w-fit">
      <p className="uppercase font-inter font-black text-white m-auto text-center text-[46px] leading-[40px] md:text-[65px] md:leading-[60px] lg:text-[96px] lg:leading-[95px]">
        gracias por <br /> conocernos
      </p>
      <div className="mx-auto h-10">
        <p className="m-auto font-sora text-[#1A2E35]">
          desarrollado por Libu Factory
        </p>
      </div>
    </div>
  );
};

export default Gracias;

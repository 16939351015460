import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import Project from './projects/Project';
import WOW from 'wowjs';
import { useEffect } from 'react';

function App() {
  const componentDidMount = () => {
    new WOW.WOW({
      live: false,
    }).init();
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <BrowserRouter>
      <div className="bg-[#141D20]">
        <div className="h-14 font-inter font-black uppercase text-white text-[20px] flex">
          <p className="m-auto">libu factory</p>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/proyectos/:project" element={<Project />} />
          <Route path="/proyectos/:project" element={<Project />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

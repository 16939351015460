import React from 'react';

const Points = () => {
  return (
    <div className="absolute w-[80%] md:w-[70%] h-[80%] md:h-[70%] flex flex-col justify-between m-auto opacity-50">
      <div className="w-full flex justify-between">
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
      </div>
      <div className="w-full flex justify-between">
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slow"></div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
        <div className="animate-pulse-slow">
          <div className="h-3 w-3 flex animate-spin-slow">
            <div className="bg-white h-3 w-1 absolute mx-1"></div>
            <div className="bg-white h-1 w-3 m-auto"></div>
          </div>
        </div>
        <div className="bg-white h-1 w-1 animate-pulse-slower"></div>
      </div>
    </div>
  );
};

export default Points;

import React from 'react';

const QueHacemos = () => {
  return (
    <div className="flex mx-auto w-11/12 lg:w-8/12 flex-col pb-10 md:pb-32">
      <div className="mb-8 md:mb-14">
        <p className="wow fadeInUp text-white uppercase font-inter font-black text-[32px] leading-10 md:text-[48px] md:leading-[45px] mb-3 lg:mb-5">
          QUE <br /> HACEmos
        </p>
        <p className="wow fadeInUp text-white font-sora font-medium text-[14px] md:text-[18px] w-9/12">
          Desarrollamos desde cero un Producto Mínimo Viable (MVP) que permita
          validar tu idea de la mejor forma.
        </p>
      </div>
      <div className="wow fadeInUp flex flex-col md:flex-row pb-5 md:pb-0 gap-10">
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] mb-3">
            consultoria
            <br />
          </p>
          <p className="text-white md:mt-11 font-sora text-[14px] md:text-[18px]">
            Conversamos cuál es tu proyecto y tu hipótesis, cuál es tu cliente
            avatar, los objetivos que tienes y cuáles son las funciones que
            quieres que tenga tu producto.
          </p>
        </div>
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] mb-3">
            diseño y
            <br />
            prototipado
          </p>
          <p className="text-white font-sora text-[14px] md:text-[18px]">
            Planteamos un diseño del MVP según las conclusiones que obtuvimos en
            la consultoria y se hace una demo de lo que se va a desarrollar.
          </p>
        </div>
        <div className="w-full md:w-4/12 md:max-w-[350px]">
          <p className="text-white uppercase font-inter font-black text-[24px] leading-[30px] mb-3">
            desarrollo
            <br />
            mvp
          </p>
          <p className="text-white font-sora text-[14px] md:text-[18px]">
            Una vez aprobado el diseño, comienza el desarrollo del software
            propiamente dicho.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QueHacemos;

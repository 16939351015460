export const projects = [
  {
    name: 'Libu',
    p: [
      'Libu es el primer proyecto que comenzamos en conjunto, en simples palabras es un ecosistema de herramientas de marketing Web3.',
      'La palabra ecosistema implica que las herramientas se relacionan entre si generando un efecto mayor a la suma de sus partes y todas las herramientas si bien son distintas todas tienen el mismo objetivo que es el marketing Web3.',
      'El marketing Web3 es una nueva forma de marketing orientada al cliente, donde se invierte la posición de poder de la marca sobre el mismo. No hay una definición particular de Marketing Web3 ya que es un término muy nuevo y todo está por explorarse, aun así, nosotros lo definimos como el sistema de estrategias que tiene una marca para empoderar al usuario y que este mismo sea quien promueva la imagen de la marca como un portaestandarte.',
      'Aun no hicimos lanzamiento oficial del website, pero entre las funciones que tiene podemos mencionar:',
    ],
    li: [
      'Market Place NFT',
      'Creacion de Tokens',
      'Airdrops automatizados a 1 click',
    ],
    image: '',
  },
  {
    name: 'Groumit',
    p: [
      'Groumit es una plataforma web3 construida como capa de utilidad sobre Lens Protocol.',
      'Consiste en una plataforma para conectar influencers y marcas para realizar campañas de marketing de influencers escalables.',
      'El influencer marketing es una de las estrategias de marketing mas rentables ya que los influencers tienen cercanía con su audiencia y le dan una imagen mas humana a la marca, el problema con el influencer marketing hoy en día es que el para hacer una campaña masiva, la marca debe encontrar a muchos influencers que atiendan a su nicho y ahí comenzar con el contacto, tratativas legales, ofertar y contra oferta, validación de contenido y verificación de que subió el contenido. Este contexto desemboca en que las marcas prefieren hacer una sola tratativa con un influencer de 1 millón de seguidores en vez de 100 de 10.000 seguidores. Lo que proponemos con groumit es una plataforma que automatiza todos estos procesos y garantiza la seguridad y transparencia gracias a utilizar un smart contract en cada momento de la negociación.',
      'El nombre groumit proviene de Grow + Meet, es un encuentro entre las marcas y los influencers que genera crecimiento para ambos',
    ],
    image: '',
  },
];
